import Chart from 'chart.js/auto';

document.addEventListener('DOMContentLoaded', () => {
    let graphicBlocks = document.querySelectorAll(".line-graphic");
    if (graphicBlocks.length > 0) {
        Array.from(graphicBlocks).map(item => initGraphic(item))
        function initGraphic(graphicBlock) {
            let dataForGraphic = JSON.parse(graphicBlock.dataset.graphicdata);
            new Chart(graphicBlock, {
                type: 'line',
                data: dataForGraphic,
                options: {
                    plugins: {
                        legend: {
                            display: true,
                            position: 'top',
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                }
            })
        }
    }
})
